<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>{{ dealDataWithZero(detail.projectDTO.projectNo) }}</span>
          <span class="detail-status">{{ detail.projectDTO.statusName }}</span>
        </div>
        <YkcDetailItemPlus :span="8" label="初次接触时间:">
          {{ dealDataWithZero(detail.projectDTO.startTime) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="目的地名称:">
          {{ dealDataWithZero(detail.projectDTO.destination) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="建设年份:">
          {{ dealDataWithZero(detail.projectDTO.year) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="所属部门:">
          {{ dealDataWithZero(detail.projectDTO.departmentName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="BD人员:">
          {{ dealDataWithZero(detail.projectDTO.bdName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="手机号:">
          {{ dealDataWithZero(detail.projectDTO.phone) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="24" label="项目可见人员:">
          {{ dealDataWithZero(detail.projectDTO.lookIdsName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="线索状态:">
          {{
            dealDataWithZero(
              `${detail.projectDTO.clueStatus}` === '3'
                ? detail.projectDTO.clueStatusName
                : `${detail.projectDTO.clueStatusName},${detail.projectDTO.clueReason}`
            )
          }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="站点性质:">
          {{ dealDataWithZero(detail.projectDTO.stationTypeName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="建设状态:">
          {{ dealDataWithZero(detail.projectApprovalVO.investStatusName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="施工方:">
          {{ dealDataWithZero(detail.projectApprovalVO.conParty) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="24" label="详细地址:">
          {{ dealDataWithZero(detail.projectDTO.address) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="场地人员联系方式:">
          {{ dealDataWithZero(detail.projectDTO.stationContactPhone) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="项目来源:">
          {{ dealDataWithZero(detail.projectDTO.projectSourceName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus
          v-if="`${detail.projectDTO.projectSource}` === '2'"
          :span="8"
          label="公司名称:">
          {{ dealDataWithZero(detail.projectDTO.company) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="24" label="现场照片:">
          <div
            class="pics-box"
            v-if="detail.projectDTO.scenePic && detail.projectDTO.scenePic.length">
            <el-image
              v-for="(item, index) in detail.projectDTO.scenePic"
              :src="item.url"
              :key="index"
              style="width: 100px; height: 100px"
              :zoom-rate="1.2"
              :max-scale="7"
              :min-scale="0.2"
              :preview-src-list="detail.projectDTO.scenePic.map(item => item.url)"
              :initial-index="4"
              fit="cover" />
          </div>
          <div v-else>——</div>
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="项目类型:">
          {{ dealDataWithZero(detail.projectApprovalVO.projectTypeName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="所属物业:">
          {{ dealDataWithZero(detail.projectApprovalVO.propertyName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="车位使用率:">
          {{
            dealDataWithZero(
              detail.projectApprovalVO.parkingRate ? `${detail.projectApprovalVO.parkingRate}%` : ''
            )
          }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="目的地户数:">
          {{ dealDataWithZero(detail.projectApprovalVO.destNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="目的地停车位数量:">
          {{ dealDataWithZero(detail.projectApprovalVO.destParkingNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="拟签约年限:">
          {{ dealDataWithZero(detail.projectApprovalVO.signYear) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="预计物业条款(分佣):">
          {{ dealDataWithZero(detail.projectApprovalVO.propertyTerms) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="目的地停车位属性(临停):">
          {{ dealDataWithZero(detail.projectApprovalVO.parkingStopNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="目的地停车位属性(物业租赁):">
          {{ dealDataWithZero(detail.projectApprovalVO.parkingPropertyNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="目的地停车位属性(产权):">
          {{ dealDataWithZero(detail.projectApprovalVO.parkingRightNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="通讯信号:">
          {{ dealDataWithZero(detail.projectApprovalVO.commSignalName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="现场照明:">
          {{ dealDataWithZero(detail.projectApprovalVO.lightingName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="运营商名称:">
          {{ dealDataWithZero(detail.projectApprovalVO.operatorNameName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="是否需要报装:">
          {{ dealDataWithZero(showYesOrNo[detail.projectApprovalVO.report]) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus
          :span="8"
          label="电费情况:"
          v-if="detail.projectApprovalVO.report === '0'">
          {{
            dealDataWithZero(
              detail.projectApprovalVO.eleWay ? `${detail.projectApprovalVO.eleWay} 元/度` : ''
            )
          }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus
          :span="8"
          label="拟报装电容:"
          v-if="detail.projectApprovalVO.report === '1'">
          {{
            dealDataWithZero(
              detail.projectApprovalVO.capacity ? `${detail.projectApprovalVO.capacity} KW` : ''
            )
          }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus
          :span="24"
          label="报装材料:"
          v-if="detail.projectApprovalVO.report === '1'">
          <div>
            <div
              class="file-wrap"
              v-for="file in detail.projectApprovalVO.attachment"
              :key="file.url">
              <a class="a-link">{{ file.name }}</a>
              <span class="link file-link" @click="downloadOSSFile(file.url, file.name)">下载</span>
            </div>
          </div>
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="取电主开关:">
          {{ dealDataWithZero(eleFlowAndPower) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="安装环境:">
          {{ dealDataWithZero(detail.projectApprovalVO.envName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="安装基础:">
          {{ dealDataWithZero(detail.projectApprovalVO.baseName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="配电箱安装基础:">
          {{ dealDataWithZero(detail.projectApprovalVO.eleBoxBaseName) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="主电缆距离:">
          {{ dealDataWithZero(detail.projectApprovalVO.distance) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="场地方是否同意铝合金主电缆:">
          {{ dealDataWithZero(showYesOrNo[detail.projectApprovalVO.alloyCable]) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="私桩数量:">
          {{ dealDataWithZero(detail.projectApprovalVO.privatePileNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="是否存在飞线情况:">
          {{ dealDataWithZero(showYesOrNo[detail.projectApprovalVO.flyingLine]) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="签约投建数量:">
          {{ dealDataWithZero(detail.projectCompeteDTO.signNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="首批投建数量:">
          {{ dealDataWithZero(detail.projectCompeteDTO.firstNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="投建模型建议投建数量:">
          {{ dealDataWithZero(detail.projectCompeteDTO.num) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="24" label="图片资料:">
          <div>
            <div class="file-wrap" v-for="file in detail.projectCompeteDTO.picData" :key="file.url">
              <a class="a-link">{{ file.name }}</a>
              <span class="link file-link" @click="downloadOSSFile(file.url, file.name)">下载</span>
            </div>
          </div>
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="24" label="项目情况介绍:">
          <div class="project-detail-table-pre">
            {{ dealDataWithZero(detail.projectApprovalVO.intro) }}
          </div>
        </YkcDetailItemPlus>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>电动汽车信息</span>
        </div>
        <YkcDetailItemPlus :span="8" label="电动汽车总数量:">
          {{ dealDataWithZero(detail.projectCarDTO.eleCarNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地面数量:">
          {{ dealDataWithZero(detail.projectCarDTO.eleCarUpNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地下数量:">
          {{ dealDataWithZero(detail.projectCarDTO.eleCarDownNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="纯电动汽车数量:">
          {{ dealDataWithZero(detail.projectCarDTO.pureCarNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地面数量:">
          {{ dealDataWithZero(detail.projectCarDTO.pureCarUpNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地下数量:">
          {{ dealDataWithZero(detail.projectCarDTO.pureCarDownNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="油车总数量:">
          {{ dealDataWithZero(detail.projectCarDTO.oilCarNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地面数量:">
          {{ dealDataWithZero(detail.projectCarDTO.oilCarUpNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地下数量:">
          {{ dealDataWithZero(detail.projectCarDTO.oilCarDownNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="油电比:">
          {{ dealDataWithZero(oilCarNum2eleCarNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地面油电比:">
          {{ dealDataWithZero(oilCarUpNum2eleCarUpNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="地下油电比:">
          {{ dealDataWithZero(oilCarDownNum2eleCarDownNum) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="是否需要指示牌:">
          {{ dealDataWithZero(showYesOrNo[detail.projectCarDTO.signage]) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="8" label="是否需要其他物料:">
          {{ dealDataWithZero(showYesOrNo[detail.projectCarDTO.otherMaterial]) }}
        </YkcDetailItemPlus>
        <YkcDetailItemPlus :span="24" label="电动出租车/网约车数量:">
          {{ dealDataWithZero(detail.projectCarDTO.taxiNum) }}
        </YkcDetailItemPlus>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>竞品信息</span>
        </div>
        <YkcDetailItemPlus :span="24" label="是否存在竞争对手:">
          {{ dealDataWithZero(showYesOrNo[detail.projectCompeteDTO.compete]) }}
        </YkcDetailItemPlus>
        <div v-if="detail.projectCompeteDTO.compete === '1'">
          <div v-for="(item, index) in detail.projectCompeteDTO.competeData" :key="index">
            <YkcDetailItemPlus :span="12" :label="`竞争对手${index + 1}名称:`">
              {{ dealDataWithZero(item.name) }}
            </YkcDetailItemPlus>
            <YkcDetailItemPlus :span="12" :label="`竞争对手${index + 1}的充电桩数量:`">
              {{ dealDataWithZero(item.num) }}
            </YkcDetailItemPlus>
            <YkcDetailItemPlus :span="12" :label="`竞争对手${index + 1}的电价:`">
              {{ dealDataWithZero(item.elePrice ? `${item.elePrice} 元/度` : '') }}
            </YkcDetailItemPlus>
            <YkcDetailItemPlus :span="12" :label="`竞争对手${index + 1}的服务费:`">
              {{ dealDataWithZero(item.eleService ? `${item.eleService} 元/度` : '') }}
            </YkcDetailItemPlus>
          </div>
        </div>
      </YkcDetailPlus>
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>操作记录</span>
        </div>
        <div>
          <ykc-table
            :tableConfig="{
              maxHeight: '300',
            }"
            ref="YkcTable"
            class="project-detail-table"
            :cellStyle="{ height: 'auto' }"
            :data="detail.projectRecordDTOS"
            :columns="tableColumns"></ykc-table>
        </div>
      </YkcDetailPlus>
    </div>
  </scroll-layout>
</template>

<script>
  import { icProject } from '@/service/apis';
  import { dealDataWithZero, downloadOSSFile } from '@/utils/index';
  import { showYesOrNo } from './constants';

  export default {
    name: 'CreateProjectDetail',
    data() {
      return {
        showYesOrNo,
        detail: {
          projectDTO: {
            startTime: '',
            destination: '',
            year: undefined,
            bd: '',
            phone: undefined,
            lookIds: undefined, // 初始化为[],会导致第一次进入没有选项
            clueStatus: '',
            clueReason: '',
            projectSource: '',
            company: '',
            stationType: '',
            stationContactName: '',
            stationContactPhone: '',
            addressId: [],
            provinceId: '',
            cityId: '',
            districtId: '',
            detailAddress: '',
            scenePic: [],
          },

          projectApprovalVO: {
            attachment: [],
            projectType: '',
            investStatus: '',
            propertyName: '',
            conParty: '',
            parkingRate: '',
            report: '',
            eleWay: '',
            capacity: '',
            destNum: '',
            destParkingNum: '',
            signYear: '',
            propertyTerms: '',
            parkingStopNum: '',
            parkingPropertyNum: '',
            parkingRightNum: '',
            commSignal: '',
            lighting: '',
            operatorId: undefined,
            env: '',
            base: '',
            eleBoxBase: '',
            distance: '',
            alloyCable: '',
            privatePileNum: '',
            flyingLine: '',
            intro: '',
          },

          projectCarDTO: {
            eleFlow: '',
            power: '',
            eleCarNum: '',
            eleCarUpNum: '',
            eleCarDownNum: '',
            pureCarNum: '',
            pureCarUpNum: '',
            pureCarDownNum: '',
            oilCarNum: '',
            oilCarUpNum: '',
            oilCarDownNum: '',
            taxiNum: '',
            signage: '',
            otherMaterial: '',
          },

          projectCompeteDTO: {
            picData: [],
            compete: '',
            num: '',
            firstNum: '',
            signNum: '',
            competeData: [
              {
                name: '',
                num: undefined,
                elePrice: undefined,
                eleService: undefined,
              },
            ],
          },
        },
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        tableColumns: [
          { label: '操作时间', prop: 'createTime' },
          { label: '操作人', prop: 'createByName' },
          {
            label: '状态',
            prop: 'totalStatusName',
            scopedSlots: {
              default: ({ row }) => {
                return <span class="project-detail-table-pre">{row.totalStatusName}</span>;
              },
            },
          },
        ],
      };
    },
    computed: {
      /** 取电主开关 */
      eleFlowAndPower() {
        let str = '';
        if (this.detail.projectApprovalVO.eleFlow) {
          str += `电流 ${this.detail.projectApprovalVO.eleFlow} A`;
        }
        if (this.detail.projectApprovalVO.power) {
          str += ` 功率 ${this.detail.projectApprovalVO.power} KW`;
        }
        return str;
      },
      // 油电比地上
      oilCarUpNum2eleCarUpNum() {
        if (
          !this.detail.projectCarDTO.eleCarUpNum ||
          this.detail.projectCarDTO.eleCarUpNum === '0'
        ) {
          return '/';
        }
        return `${
          (this.detail.projectCarDTO.oilCarUpNum / this.detail.projectCarDTO.eleCarUpNum).toFixed(
            4
          ) * 100
        }%`;
      },
      // 油电比
      oilCarNum2eleCarNum() {
        if (!this.detail.projectCarDTO.eleCarNum || this.detail.projectCarDTO.eleCarNum === '0') {
          return '/';
        }
        return `${
          (this.detail.projectCarDTO.oilCarNum / this.detail.projectCarDTO.eleCarNum).toFixed(4) *
          100
        }%`;
      },
      // 油电比地下
      oilCarDownNum2eleCarDownNum() {
        if (
          !this.detail.projectCarDTO.eleCarDownNum ||
          this.detail.projectCarDTO.eleCarDownNum === '0'
        ) {
          return '/';
        }
        return `${
          (
            this.detail.projectCarDTO.oilCarDownNum / this.detail.projectCarDTO.eleCarDownNum
          ).toFixed(4) * 100
        }%`;
      },
    },
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.getDetail();
    },
    methods: {
      dealDataWithZero,
      downloadOSSFile,
      /**
       * 获取详情数据
       */
      getDetail() {
        const { id } = this.$route.query;
        icProject.detail({ id }).then(response => {
          console.log('getDetail response', response);
          Object.assign(this.detail, response);
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    padding: 10px;
    display: flex;
    flex-flow: column;
    .box-card {
      margin: 5px 0;
      .file-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 26px;
        margin-left: 10px;
        .a-link {
          color: #4d8cfd;
          cursor: pointer;
        }
        .file-link {
          color: #4d8cfd;
          cursor: pointer;
        }
      }
      .detail-status {
        color: red;
        margin-left: 20px;
      }
      .pics-box {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
      .baseInfo {
        display: flex;
        font-size: 14px;
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          .label {
            color: #9b9b9b;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            & > div {
              width: calc(100% / 3);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .project-detail-table-pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
</style>
<style lang="scss">
  .project-detail-table {
    .el-table {
      .el-table__body-wrapper {
        .el-table__row {
          .el-table__cell {
            .cell {
              height: auto;
            }
          }
        }
      }
    }
  }
</style>
