/**
 * 权限和角色相关
 * 新增 project:icProject:list:add
 * 删除 project:icProject:list:delete
 * 详情 project:icProject:list:detail
 * 准备立项 project:icProject:list:prepare  p_准备立项
 * 立项申请 project:icProject:list:apply p_立项申请
 * 项目勘测 project:icProject:list:survey p_项目勘测
 * 项目签约 project:icProject:list:sign p_项目签约
 * 等待施工 project:icProject:list:waitCon p_等待施工
 * 施工中  project:icProject:list:underCon p_施工中
 * 项目完成 project:icProject:list:finish p_项目完成
 * 项目上线 project:icProject:list:online p_项目上线
 * 全部数据 project:icProject:list:all p_全部数据
 *
 */

export const PermAndCodeMap = {
  10010: 'project:icProject:list:prepare',
  10011: 'project:icProject:list:apply',
  10012: 'project:icProject:list:apply',
  10013: 'project:icProject:list:survey',
  10014: 'project:icProject:list:sign',
  10015: 'project:icProject:list:waitCon',
  10016: 'project:icProject:list:waitCon',
  10017: 'project:icProject:list:underCon',
  10018: 'project:icProject:list:finish',
  10019: 'project:icProject:list:online',
  10020: 'project:icProject:list:online',
  10021: 'project:icProject:list:apply',
};

// 测试
export const showYesOrNo = {
  0: '否',
  1: '是',
};

export const stationTypeData = [
  { id: '1', name: '住宅类' },
  { id: '2', name: '临停公共车场/写字楼/工业园等' },
];

export const clueStatusData = [
  { id: '1', name: '待定' },
  { id: '2', name: '终止' },
  { id: '3', name: '可持续推进' },
];

export const reportData = [
  { id: '0', name: '否' },
  { id: '1', name: '是' },
];
export const commSignalData = [
  { id: '1', name: '好' },
  { id: '2', name: '差' },
  { id: '3', name: '无信号' },
];
export const lightingData = [
  { id: '1', name: '好' },
  { id: '2', name: '差' },
  { id: '3', name: '无' },
];
export const operatorIdData = [
  { id: '1', name: '移动' },
  { id: '2', name: '联通' },
  { id: '3', name: '电信' },
];
export const baseData = [
  { id: '1', name: '落地安装' },
  { id: '2', name: '壁挂安装' },
];
export const eleBoxBaseData = [
  { id: '1', name: '落地安装' },
  { id: '2', name: '壁挂安装' },
];
export const alloyCableData = [
  { id: '0', name: '否' },
  { id: '1', name: '是' },
];
export const flyingLineData = [
  { id: '0', name: '否' },
  { id: '1', name: '是' },
];
export const signageData = [
  { id: '0', name: '否' },
  { id: '1', name: '是' },
];
export const otherMaterialData = [
  { id: '0', name: '否' },
  { id: '1', name: '是' },
];
export const competeData = [
  { id: '0', name: '否' },
  { id: '1', name: '是' },
];
